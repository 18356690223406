<template>
    <div stripe border v-loading="meta.loading"
         element-loading-text = "加载中,请稍后..."
         element-loading-background="rgba(0, 0, 0, 0.7)">
        <mt >
            <el-form :inline="true" >
                <el-form-item>
                    <el-button  style="margin-right: 30px"  @click="addSeller" v-if="isAdmin||isHr">添加销售员</el-button>
                </el-form-item>
                <el-form-item>
                    <el-input  v-model="params.name" placeholder="请输入销售员姓名或电话" clearable></el-input>
                </el-form-item>
                <el-form-item label="上级">
                    <el-select v-model="params.parentId" filterable remote placeholder="请输入上级姓名或电话" clearable
                               :remote-method="_queryAll"
                               :loading="meta.loading">
                        <el-option v-for="s in meta.all" :value="s.id" :label="s.name+'('+s.mobile+')'"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="params.state" style="width: 160px">
                        <el-option  label="请选择" value=""></el-option>
                        <el-option  label="在职" :value="1"></el-option>
                        <el-option  label="离职" :value="0"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button @click="q">查询</el-button>
                </el-form-item>

            </el-form>
        </mt>
        <el-card style="margin: 30px" shadow="always">
            <div slot="header" class="clearfix">
                <span class="title">销售员信息</span>
            </div>
            <el-table  height="700px" :data="sellers" class="funBtn">
                <el-table-column label="NO." width="70px" type="index"></el-table-column>
                <el-table-column label="姓名" prop="name"></el-table-column>
                <el-table-column label="手机号" prop="mobile"></el-table-column>
                <el-table-column label="身份" prop="type" sortable>
                    <template slot-scope="scope">
                        <span v-if="scope.row.type==1">经理</span>
                        <span v-if="scope.row.type==2">总监</span>
                        <span v-if="scope.row.type==3">业务员</span>
                    </template>
                </el-table-column>
                <el-table-column label="上级" prop="parent" min-width="100px">
                    <template slot-scope="scope">
                    <span v-if="scope.row['parent']">
                        {{scope.row.parent.name}}({{scope.row.parent.mobile}})
                    </span>
                    </template>
                </el-table-column>
                <el-table-column label="注册时间" prop="updateTime" min-width="100px"></el-table-column>
                <el-table-column label="状态" prop="updateTime" sortable>
                    <template slot-scope="scope">
                        <span v-if="scope.row.state==0">离职</span>
                        <span v-else>在职</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="430">
                    <template slot-scope="scope">
                        <template v-if="scope.row.state==1">
                            <el-button  @click="onStaffs(scope.row)" v-if="!isHr">管理员工</el-button>
                        </template>
                        <el-button  @click="onUsers(scope.row)" v-if="!isHr">服务客户</el-button>
                        <el-button  @click="onEdit(scope.row)"  v-if="isAdmin||isHr">编辑信息</el-button>
                        <el-button  @click="onDelete(scope.row)"  v-if="isAdmin||isHr" type="danger">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="pager">
                <el-pagination @size-change="onQuery"
                               @current-change="onQuery"
                               :current-page.sync="params.page"
                               :page-sizes="[50, 100]"
                               :page-size.sync="params.size"
                               layout="sizes, prev, pager, next, total"
                               :total="params.total">

                </el-pagination>
            </div>

            <el-dialog :visible.sync="showDialog" width="400px" ref="editDialog" title="添加/编辑销售员">
                <el-form label-width="120px"  v-model="newSeller" :model="newSeller" :rules="rules"
                         ref="newSeller">
                    <el-form-item label="姓名" prop="name">
                        <el-input v-model="newSeller.name"></el-input>
                    </el-form-item>
                    <el-form-item label="手机号" prop="mobile">
                        <el-input v-model="newSeller.mobile"></el-input>
                    </el-form-item>
                    <el-form-item label="在职状态" prop="state" v-if="newSeller.id">
                        <el-radio-group v-model="newSeller.state">
                            <el-radio :label="1">在职</el-radio>
                            <el-radio :label="0">离职</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="身份" prop="type">
                        <el-select v-model="newSeller.type" @change="onSelectType">
                            <el-option label="业务员" :value="3"></el-option>
                            <el-option label="总监" :value="2"></el-option>
                            <el-option label="经理" :value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="上级">
                        <el-select v-model="newSeller.parentId" filterable remote placeholder="请输入上级姓名或电话" clearable
                                   :remote-method="_querySellers"
                                   :loading="meta.loading">
                            <el-option v-for="s in meta.sellers" :value="s.id" :label="s.name+'('+s.mobile+')'"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer">
                    <el-button type="primary"  @click="onCreateOk">确定</el-button>
                    <el-button  @click="onCreateCancel">取消</el-button>
                </div>
            </el-dialog>
            <el-dialog :visible.sync="showStaffs">
                <el-tree
                        class="filter-tree"
                        :data="meta.staffs['sub']"
                        show-checkbox
                        node-key="id"
                        default-expand-all
                        :props="meta.treeProp"
                        v-loading="meta.treeLoad"
                        ref="tree">
                <span class="custom-tree-node" slot-scope="{ node, data }">
                    <span>{{ data.name }}</span>(<span>{{ data.mobile }}</span>)
                </span>
                </el-tree>

                <div slot="footer">
                    <el-button  @click="showStaffs=false">取消</el-button>
                </div>
            </el-dialog>
            <el-dialog :visible.sync="showUsers">
                <div slot="footer">
                    <el-button  @click="showUsers=false">取消</el-button>
                </div>
            </el-dialog>
        </el-card>
    </div>

</template>

<script>
    import mt from "@/components/MainTop.vue"

    export default {
        name: "Seller",
        components:{
            mt
        },
        data() {
            return {
                params: {
                    parentId:null,
                    name:"",
                    page: 1,
                    size: 50,
                    total: 0,
                    state:1
                },
                showDialog: false,
                newSeller: {
                    name: "",
                    mobile: "",
                    id: "",
                    parentId: null,
                    state:1,
                    type: 3,
                    parent: {
                        name: "",
                        mobile: "",
                        id: -1
                    },
                },
                rules: {
                    name: [{required: true, message: '请输入销售员姓名', trigger: 'blur'}],
                    mobile: [{required: true, message: '请输入销售员手机号', trigger: 'blur',pattern:/^1\d{10}$/},],
                },
                sellers: [],
                showStaffs: false,
                showUsers: false,
                meta: {
                    loading: false,
                    sellers: [],
                    staffs: {},
                    treeProp: {
                        children: 'sub',
                        label: 'name'
                    },
                    treeLoad: false,
                    all:[]
                },

            }
        },
        mounted() {
            this._queryAll();
            this._querySellers("");
            this.onQuery();
        },
        methods: {
            _queryAll(name,callback){
                let _this=this;
                this.$http.get("/api/seller/find", {params: {name: name,page:0,size:5000}})
                    .then(rsp => {
                        if (rsp.data.status == 200) {
                            this.meta.all = rsp.data.data || [];
                            if(callback){
                                callback();
                            }
                        }
                    })
            },
            _querySellers(name,callback) {
                let _this=this;
                 this.$http.get("/api/seller/find", {params: {name: name,page:0,size:5000,state:1}})
                    .then(rsp => {
                        if (rsp.data.status == 200) {
                            this.meta.sellers = rsp.data.data || [];
                            if(callback){
                                callback();
                            }
                        }
                    })
            },
            q(){
                this.params.page=1;
                this.onQuery();
            },
            onQuery() {
                let _this = this;
                this.meta.loading = true
                let _p={
                    parentId:_this.params.parentId,
                    state:_this.params.state,
                    name:_this.params.name,
                    page:_this.params.page-1,
                    size: _this.params.size,
                }
                this.$http.get("/api/seller/find", {params: _p})
                    .then(rsp => {
                        if (rsp.data.status == 200) {
                            _this.sellers = rsp.data.data || [];
                            _this.params.total=rsp.data.count;
                            this.meta.loading= false
                        }
                    });
                this._querySellers();
            },
            addSeller() {
                this.clearData();
                this.showDialog = true;

            },
            onCreateOk() {
                this.$refs['newSeller'].validate((valid) => {
                        if (!valid) {
                            return
                        }
                        let param = this.newSeller;
                        if ((param.parentId)&&(param.parentId == param.id)) {
                            this.$message.error("不能设置自己为上级");
                            return;
                        }
                        if (this.newSeller.id) {//编辑
                            this.$http.patch("/api/seller/edit/" + param.id, param)
                                .then((rsp) => {
                                    if (rsp.data.status == 200) {
                                        this.$message.success("编辑成功");
                                        this.showDialog = false;
                                        this.clearData();
                                        this.onQuery();
                                        this._querySellers();
                                    }
                                })
                                .catch((rsp) => {
                                    this.$message.error(rsp.response.data.message);
                                })
                        } else {//新建
                            this.$http.post("/api/seller/create", param)
                                .then((rsp) => {
                                    if (rsp.data.status == 200) {
                                        this.$message.success("创建成功");
                                        this.showDialog = false;
                                        this.clearData();
                                        this.onQuery();
                                    }
                                })
                                .catch((rsp) => {
                                    this.$message.error(rsp.response.data.message);
                                })
                        }
                    }
                );
            },
            onCreateCancel() {
                this.clearData();
                this.showDialog = false;
            },
            clearData() {
                this.newSeller = {
                    name: "",
                    mobile: "",
                    id: "",
                    parentId: "",
                    type: "",
                    state:1,
                    parent: {
                        name: "",
                        mobile: "",
                        id: ""
                    },
                }
            },
            onStaffs(row) {
                this.showStaffs = true;
                this.meta.treeLoad = true;
                this.$http.get("/api/seller/staffs/" + row.id)
                    .then(rsp => {
                        if (rsp.data.status == 200) {
                            this.meta.staffs = rsp.data.data || {};
                            this.meta.treeLoad = false;
                        }
                    })
                    .catch(rsp => {
                        this.meta.treeLoad = false;
                        this.$message.error(rsp.response.data.message);
                    });

            },
            onUsers(row) {
                this.$router.push({
                    name: 'Customer',
                    params: {
                        sellerId: row.id
                    }
                })
            },
            onEdit(row) {
                this.clearData();
                let _parent={};
                if(row['parent']){
                    _parent=row['parent'];
                }
                this.newSeller = {
                    name: row.name || '',
                    mobile: row.mobile || '',
                    id: row.id || '',
                    parentId: _parent.id,
                    type: row.type,
                    state:row.state,
                    parent: _parent,
                }

                this.$refs['editDialog'].$forceUpdate();
                this.showDialog = true;

            },
            onDelete(row){
                this.$confirm('删除后该业务员不可恢复，是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'error'
                }).then(() => {
                    this.$http.delete("/api/seller/staffs/" + row.id)
                        .then(rsp => {
                            if (rsp.data.status == 200) {
                                this.$message.success("删除成功");
                                this.onQuery();
                            }
                        })
                        .catch(rsp => {
                            this.meta.treeLoad = false;
                            this.$message.error(rsp.response.data.message);
                        });
                });
            },
            onSelectType(type) {
                this.newSeller.parentId = "";
                this.newSeller.parent = {};
                this._querySellers(this.newSeller.name);
            },
            onExportStaff(row) {
                return
            },
            onExportUser(row) {
                return;
            },

        }


    }
</script>

<style scoped>

</style>
